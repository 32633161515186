<template>
  <div>
    <h1 style="text-align: center">升级相关事宜请联系客服</h1>
<!--    <el-button type="primary" size="large" @click="updateJobStatus">买,记得去改team表中的额数限制</el-button>-->
    <img src="../assets/images/wechat-service-other.jpg" height="430" width="430"/>
  </div>
</template>

<script>
import {updateJobStatus} from "../api/api"

export default {
  name: "Upgrade",
  mounted() {

  },
  data(){
    return{
      contractId: this.$route.query.contractId,
      type: this.$route.query.type
    }
  },
  methods:{
    updateJobStatus(){
      updateJobStatus().then(res => {
        if (res.data.code === 0){
          this.$message({
            message: '恭喜你，购买成功',
            type: 'success'
          });
          if (this.type==='view') {
            this.$router.push({
              name: 'contractView',
              query: {
                id: this.contractId,
                reload: 1
              }
            })
          }else{
            this.$router.push({
              name:'contractEdit',
              query: {
                contractId: this.contractId
              }
            })
          }
        }
      }).catch(e => {
        console.log("购买失败 => ",e)
        this.$message({
          message: '购买失败,请联系管理员',
          type: 'error'
        });
      });
    },
  },
  directives:{

  }
}
</script>

<style scoped>

</style>
